﻿import src from './logboek.html?url';
import logboekTextDialogHtml from './logboekTextDialog.html?url';

import closeSvg from '../../images/svgs/close.svg';
import icSaveSvg from '../../images/svgs/ic_save.svg';

const angular = window.angular;

window.app.controller('gridLogboekCtrl', [
  '$rootScope',
  '$scope',
  '$sce',
  '$mdDialog',
  'modelService',
  'userAccountService',
  'gridLogboek',
  'gridFactory',
  gridLogboekCtrl,
]);

function gridLogboekCtrl(
  $rootScope,
  $scope,
  $sce,
  $mdDialog,
  modelService,
  userAccountService,
  gridLogboek,
  gridFactory,
) {
  $scope.src = $sce.trustAsResourceUrl(src);

  $scope.views = gridLogboek.getViews();
  $scope.selectedView = $scope.views[0].id;
  $scope.groupings = gridLogboek.getGroupings();
  $scope.selectedGrouping = $scope.groupings[0].id;
  $scope.selectionMethod = 'Cell';

  $scope.disabled = true;
  $scope.projectId;
  let projectId;

  function setDisabled(numSelected) {
    $scope.deleteDisabled = numSelected === 0;
    $scope.updateDisabled = numSelected !== 1;
  }

  function setProject(model) {
    setDisabled(0);
    projectId = model.project ? model.project.id : undefined;
    $scope.projectId = projectId;
  }

  function init(model) {
    return model.grid.LOGBOEK;
  }

  $rootScope.$on('model-selectie', (_, model) => {
    if (init(model)) {
      setDisabled(model.selectie.length);
      $scope.$apply();
    }
  });

  $rootScope.$on('model-project', (_, model) => {
    if (init(model)) {
      setProject(model);
      $scope.$apply();
    }
  });

  $rootScope.$on('model-grid', (_, model) => {
    if (init(model)) {
      setProject(model);
      $scope.$apply();
    }
  });

  function getSelectedLogboekLines() {
    return gridFactory.getRowsFromRange().map((row) => row.item);
  }

  $scope.deleteLogboek = () => {
    const ids = getSelectedLogboekLines().map((item) => item.id);
    if (confirm('Geselecteerde regels verwijderen?')) {
      userAccountService.deleteProjectLogboek(ids).then(refresh);
    }
  };

  $scope.createLogboek = () => textDialog('create');
  $scope.updateLogboek = () => textDialog('update');

  function textDialog(action) {
    $mdDialog.show({
      controller: textDialogController,
      templateUrl: $sce.trustAsResourceUrl(logboekTextDialogHtml),
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: false,
      locals: { action },
    });
  }

  function textDialogController($scope, $mdDialog, action) {
    $scope.closeSvg = closeSvg;
    $scope.icSaveSvg = icSaveSvg;

    $scope.caption = 'Nieuwe aantekening';
    $scope.text = '';
    let logboek = {};

    if (action === 'update') {
      const lines = getSelectedLogboekLines();
      if (lines.length === 1) {
        logboek = lines[0];
        $scope.caption = 'Aantekening bewerken';
        $scope.text = logboek.tekst;
      } else {
        alert('Selecteer één regel om te bewerken');
        hide();
      }
    }

    $scope.oldText = $scope.text;

    function hide() {
      $mdDialog.hide();
    }

    $scope.cancel = function () {
      hide();
    };

    function run() {
      switch (action) {
        case 'create':
          return userAccountService.postProjectLogboek(projectId, $scope.text);
        case 'update':
          return userAccountService.putProjectLogboek(logboek.id, $scope.text);
      }
    }

    $scope.save = function () {
      run().then(refresh);
      hide();
    };
  }
  textDialogController.$inject = ['$scope', '$mdDialog', 'action'];

  function refresh() {
    const model = modelService.getModel();
    gridLogboek.refresh(model);
  }

  $scope.toggleFilterRow = function () {
    gridFactory.toggleFilterRow();
  };

  $scope.setView = function (view) {
    if (view) {
      $scope.selectedView = view.name;
      var index = $scope.views.indexOf(view);
      gridLogboek.setView(index);
    }
  };

  $scope.setSelectionMethod = function (e, method) {
    switch (method) {
      case 'Cell':
        $scope.selectionMethod = 'Cell';
        break;
      case 'Row':
        $scope.selectionMethod = 'Row';
        break;
    }
    gridFactory.setSelectionMethod($scope.selectionMethod);
  };

  $scope.setGrouping = function (grouping) {
    $scope.selectedGrouping = grouping;
    gridLogboek.setGrouping($scope.selectedGrouping);
  };

  $scope.expandAllGroups = function () {
    gridFactory.expandAllGroups();
  };

  $scope.collapseAllGroups = function () {
    gridFactory.collapseAllGroups();
  };

  $scope.clearFilters = function () {
    gridFactory.clearFilter();
  };

  $scope.clearSelection = function () {
    gridFactory.clearSelection();
  };

  $scope.Export2Excel = function () {
    gridFactory.Export2Excel();
  };

  $scope.getDocument = function (link) {
    window.open(link, '_blank', 'fullscreen=yes');
  };
}
