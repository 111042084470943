﻿const Slick = window.Slick;

window.app.factory('gridLogboek', [
  '$rootScope',
  'modelService',
  'gridFactory',
  gridLogboek,
]);

function gridLogboek($rootScope, modelService, gridFactory) {
  const API = {
    setGrouping,
    getGroupings,
    getViews,
    setView,
    getLogboek,
    refresh,
  };

  const columnObjects = {
    tekst: {
      name: 'Tekst',
      width: 400,
      minWidth: 100,
      maxWidth: 600,
    },
    opgevoerd_door: {
      name: 'Gebruiker',
      toolTip: 'Laatst bewerkt door',
      width: 200,
      minWidth: 100,
      maxWidth: 300,
    },
    opgevoerd: {
      name: 'Datum',
      toolTip: 'Laatst bewerkt op op',
      formatter: Slick.Formatters.DateTime,
      width: 200,
      minWidth: 100,
      maxWidth: 300,
    },
  };

  const columns = [];

  Object.keys(columnObjects).forEach((key) => {
    const column = columnObjects[key];
    column.id = column.id || key;
    columns.push(newColumn(column));
  });

  function newColumn(column) {
    function defaultValue(key, value) {
      if (column[key] === undefined) {
        column[key] = value;
      }
    }
    defaultValue('name', column.id);
    defaultValue('field', column.id);
    defaultValue('formatter', Slick.Formatters.Text);
    defaultValue('resizable', true);
    defaultValue('sortable', true);
    defaultValue('filterable', true);
    defaultValue('filter', 'Text');
    return column;
  }

  let grid = {};
  let gridfactory;

  initGrid(modelService.getModel());

  function init(model) {
    grid = model.grid;
    return grid.LOGBOEK;
  }

  function initGrid(model) {
    if (init(model)) {
      const options = {
        editable: true,
        autoEdit: true,
        enableAddRow: false,
        enableCellNavigation: true,
        asyncEditorLoading: true,
        forceFitColumns: false,
        topPanelHeight: 25,
        enableColumnReorder: true,
        showHeaderRow: true,
        enableAsyncPostRender: true,
      };

      gridfactory = Object.create(gridFactory);
      gridfactory
        .createGrid(grid.value, columns, options, grid.key)
        .then(() => {
          setView(0);
          requestLogboekData(model);
        });
    }
  }

  $rootScope.$on('model-grid', (_, model) => {
    initGrid(model);
  });

  $rootScope.$on('model-project', (_, model) => {
    if (init(model)) {
      requestLogboekData(model);
    }
  });

  let logboek;

  function getLogboek(ids) {
    return (logboek || []).filter((item) => !ids || ids.includes(0 + item.id)); // "1"
  }

  function refresh(model) {
    return requestLogboekData(model);
  }

  function requestLogboekData(model) {
    gridfactory
      .getLogboek(model)
      .then((data) => {
        logboek = data;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const groupings = [{ id: 'geen', label: 'Niets' }];

  function getGroupings() {
    return groupings;
  }

  function setGrouping(grouping, options) {
    gridfactory.setGrouping(grouping, options);
  }

  const views = [
    {
      id: 'Standaard',
      label: 'Standaard',
      columns: Object.keys(columnObjects),
    },
  ];

  function getViews() {
    return views;
  }

  function setView(viewIndex) {
    gridfactory.setView(grid.value, columns, views, viewIndex);
  }

  return API;
}
