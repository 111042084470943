﻿import { fromExtent } from 'ol/geom/Polygon';
import WKT from 'ol/format/WKT';
import * as XLSX from 'xlsx/xlsx.mjs';

import { gridMetadataTransform } from './gridMetadata';

import infoHtml from './info.html?url';

import icVisibilityOffSvg from '../../images/svgs/ic_visibility_off.svg';
import closeSvg from '../../images/svgs/close.svg';

const $ = window.$;
const Slick = window.Slick;
const toastr = window.toastr;
const angular = window.angular;

window.app.factory('gridFactory', [
  '$rootScope',
  '$sce',
  '$mdDialog',
  'modelService',
  'utilService',
  'userAccountService',
  'mapService',
  'mapexportService',
  gridFactory,
]);

function gridFactory(
  $rootScope,
  $sce,
  $mdDialog,
  modelService,
  utilService,
  userAccountService,
  mapService,
  mapexportService,
) {
  var grid;
  var allColumns;
  var columns;
  var options;
  var dataView;
  var columnFilters = {};
  var unselectedRowIds = [];
  var editRange = null;
  var isInEditMode = false;

  $.extend(Slick.Editors, {
    PrefilledText: function (args) {
      var $input;
      var defaultValue;

      this.init = function () {
        $input = $("<INPUT type=text class='editor-text' />")
          .appendTo(args.container)
          .on('keydown.nav', function (e) {
            if (
              e.keyCode === $.ui.keyCode.LEFT ||
              e.keyCode === $.ui.keyCode.RIGHT
            ) {
              e.stopImmediatePropagation();
            }
          })
          .focus()
          .select();
      };

      this.destroy = function () {
        $input.remove();
      };

      this.focus = function () {
        $input.focus();
      };

      this.getValue = function () {
        return $input.val();
      };

      this.setValue = function (val) {
        $input.val(val);
      };

      this.loadValue = function () {
        defaultValue = args.column.prefill || '';
        $input.val(defaultValue);
        $input[0].defaultValue = defaultValue;
        $input.select();
      };

      this.serializeValue = function () {
        return $input.val();
      };

      this.applyValue = function (item, state) {
        item[args.column.field] = state;
      };

      this.isValueChanged = function () {
        return (
          !($input.val() == '' && defaultValue == null) &&
          $input.val() != defaultValue
        );
      };

      this.validate = function () {
        if (args.column.validator) {
          var validationResults = args.column.validator($input.val());
          if (!validationResults.valid) {
            return validationResults;
          }
        }

        return {
          valid: true,
          msg: null,
        };
      };

      this.init();
    },
    MultilineText: function (args) {
      var $input;
      var defaultValue;

      this.init = function () {
        $input = $(
          "<TEXTAREA hidefocus rows=1 style='background:white;width:100%;height:99%;border:0;outline:0' />",
        )
          .appendTo(args.container)
          .on('keydown.nav', function (e) {
            if (
              e.keyCode === $.ui.keyCode.LEFT ||
              e.keyCode === $.ui.keyCode.RIGHT
            ) {
              e.stopImmediatePropagation();
            }
          })
          .focus()
          .select();
      };

      this.destroy = function () {
        $input.remove();
      };

      this.focus = function () {
        $input.focus();
      };

      this.getValue = function () {
        return $input.val();
      };

      this.setValue = function (val) {
        $input.val(val);
      };

      this.loadValue = function (item) {
        defaultValue = item[args.column.field] || '';
        $input.val(defaultValue);
        $input[0].defaultValue = defaultValue;
        $input.select();
      };

      this.serializeValue = function () {
        return $input.val();
      };

      this.applyValue = function (item, state) {
        item[args.column.field] = state;
      };

      this.isValueChanged = function () {
        return (
          !($input.val() == '' && defaultValue == null) &&
          $input.val() != defaultValue
        );
      };

      this.validate = function () {
        if (args.column.validator) {
          var validationResults = args.column.validator($input.val());
          if (!validationResults.valid) {
            return validationResults;
          }
        }

        return {
          valid: true,
          msg: null,
        };
      };

      this.init();
    },
  });

  // This wil render '' as the value when nothing is entered. Use `validator:
  // requiredFieldValidator` to disallow this input. Otherwise, on update,
  // convert '' to null if needed.
  Slick.Editors.Float.AllowEmptyValue = true;

  function floatFormat(value, decimals, explicitNull) {
    if (value === null) {
      return explicitNull ? 'null' : null;
    }
    value = Number(value).toFixed(decimals);
    return value;
  }

  function plusFormat(value, decimals = 2) {
    if (value !== null) {
      const formatter = decimals
        ? Slick.Formatters['Float' + decimals]
        : Slick.Formatters.Integer;
      value = formatter(null, null, value);
      if (value >= 0) {
        value = '+' + value;
      }
    }
    return value;
  }

  $.extend(Slick.Formatters, {
    Selector: function () {
      var text = `<image title='Verbergen' src='${icVisibilityOffSvg}' style='height: 18px; cursor: pointer;'>`;
      return text;
    },
    TooltipText: function (row, cell, value) {
      var text = value != null && value != undefined ? value : '';
      return (
        '<div class="gridcelltooltip">' +
        text +
        '<span class="tooltiptext">' +
        text +
        '</span></div>'
      );
    },
    test: function () {
      return 'test';
    },
    Omschrijving: function (row, cell, value, columnDef, dataContext) {
      let text = value || 'onbekend';
      try {
        const list = columnDef.dataSource(dataContext);
        text = list[value].omschrijving;
        // eslint-disable-next-line no-empty
      } catch {}
      return text;
    },
    Float1: function (row, cell, value) {
      const text = floatFormat(value, 1);
      return text;
    },
    Float2: function (row, cell, value) {
      const text = floatFormat(value, 2);
      return text;
    },
    Float3: function (row, cell, value) {
      const text = floatFormat(value, 3);
      return text;
    },
    Float3Null: function (row, cell, value) {
      const text = floatFormat(value, 3, true);
      return text;
    },
    Float4: function (row, cell, value) {
      const text = floatFormat(value, 4);
      return text;
    },
    Integer: function (row, cell, value) {
      var text = 'ONB';
      try {
        text = Math.round(Number(value));
      } catch (err) {
        text = 'ONB';
      }
      return text;
    },
    Plus2: function (row, cell, value) {
      const text = plusFormat(value, 2);
      return text;
    },
    Date: function (row, cell, value) {
      var maand = [
        { m: '1', mm: '01', mmm: 'jan', mmmm: 'jabuari' },
        { m: '2', mm: '02', mmm: 'feb', mmmm: 'februari' },
        { m: '3', mm: '03', mmm: 'mar', mmmm: 'maart' },
        { m: '4', mm: '04', mmm: 'apr', mmmm: 'april' },
        { m: '5', mm: '05', mmm: 'mei', mmmm: 'mei' },
        { m: '6', mm: '06', mmm: 'jun', mmmm: 'juni' },
        { m: '7', mm: '07', mmm: 'jul', mmmm: 'juli' },
        { m: '8', mm: '08', mmm: 'aug', mmmm: 'augustus' },
        { m: '9', mm: '09', mmm: 'sep', mmmm: 'september' },
        { m: '10', mm: '10', mmm: 'oct', mmmm: 'oktober' },
        { m: '11', mm: '11', mmm: 'nov', mmmm: 'november' },
        { m: '12', mm: '12', mmm: 'dec', mmmm: 'december' },
      ];
      var text = 'ONB';
      if (value) {
        try {
          var date = new Date(value);
          var day =
            date.getDate().length < 2 ? '0' + date.getDate() : date.getDate();
          var month = Number(date.getMonth());
          text = day + ' ' + maand[month].mmm + ' ' + date.getFullYear();
        } catch (error) {
          text = 'ONB';
        }
      }
      return text;
    },
    DatePoint: function (row, cell, value) {
      function prefix(num) {
        const prefix = num < 10 ? '0' : '';
        return prefix + num;
      }
      let text = '';
      if (value) {
        try {
          const date = new Date(value);
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const day = date.getDate();
          text = `${year}.${prefix(month)}.${prefix(day)}`;
        } catch (error) {
          text = '';
        }
      }
      return text;
    },
    DateTime: function (row, cell, value) {
      let text = 'ONB';
      if (value) {
        text = value;
        try {
          const date = new Date(value);
          text = date.toLocaleString();
          // eslint-disable-next-line no-empty
        } catch {}
      }
      return text;
    },
    Tonen: function (row, cell, value, columnDef, { tonen }) {
      return icon({
        title: `Klik om te ${tonen ? 'verbergen' : 'tonen'}`,
        icon: `${tonen ? 'visibility' : 'visibility_off'}`,
      });
    },
  });

  let gridModel;

  $rootScope.$on('model-grid', (_, model) => {
    gridModel = model.grid;
    let key;
    switch (gridModel.key) {
      case 'PLANTEKENINGEN':
        key = 'PLAN';
        break;
      case 'PROJECTKAARTEN':
        key = 'PROJECT';
        break;
      case 'NOTITIES':
        key = 'NOTITIE';
        break;
    }
    if (key) {
      gridModel.exclude = {
        key,
        message: `model-exclude-${key.toLowerCase()}`,
      };
    }
  });

  function tonenPostRender(cellNode, row, dataContext) {
    cellNode[0].firstElementChild.onclick = () => {
      dataContext.tonen = !dataContext.tonen;
      setTimeout(() => {
        // setTimeout to ensure active cell
        const { cell } = getGrid().getActiveCell();
        getGrid().updateCell(row, cell);
        let exclude =
          modelService.getModel().exclude[gridModel.exclude.key] || [];
        if (dataContext.tonen) {
          exclude = exclude.filter((id) => id != dataContext.id);
        } else {
          exclude.push(dataContext.id);
        }
        modelService.update(gridModel.exclude.message, exclude);
      });
    };
  }

  let activeElement,
    activeGrid = 'NONE';

  $rootScope.$on('model-grid', (_, model) => {
    if (model.grid.NONE) {
      destroyGrid();
    }
  });

  var factory = {
    createGrid,
    destroyGrid,
    updateData,
    resizeGrid,
    getGrid,
    getDataview,
    setSelectionMethod,
    setColumns,
    setView,
    setFilter,
    clearFilter,
    clearSelection,
    toggleFilterRow,
    getSelectedFeatures,
    setGrouping,
    expandAllGroups,
    collapseAllGroups,
    Export2Csv,
    Export2Excel,
    Export2Pdf,
    Export2Kml,
    getData,
    invalidate,
    getMetaData,
    getKlicNetwerken,
    getPlantekeningen,
    getLogboek,
    getRowsFromRange,
    DeleteRows,
    editCell,
    highlightRow,
    icon,
    tonenPostRender,
  };

  function icon({ icon, cls, title, onclick, style }) {
    style = style || '';
    style = style + 'font-size: 20px; vertical-align: middle;';
    if (onclick) {
      style = style + 'cursor: pointer;';
    }
    cls = cls || 'material-icons-outlined';
    return `<i title="${title}" onclick="${onclick}" style="${style}" class="${cls}">${icon}</i>`;
  }

  (function switchPresentatieOnProjectModus() {
    function maybeSwithPresentatie(condition, presentatie) {
      if (condition) {
        modelService.update('model-presentatie', presentatie);
        return true;
      }
    }
    var previousModus = '';
    $rootScope.$on('model-modus', (_, model) => {
      const modus = model.modus(),
        oldP = previousModus.includes('P'),
        newP = modus.includes('P');
      if (!maybeSwithPresentatie(newP && !oldP, 'onderzoekstatus')) {
        maybeSwithPresentatie(oldP && !newP, 'default');
      }
      previousModus = modus;
    });
  })();

  function editCell(row, cell) {
    getGrid().gotoCell(row, cell, true);
  }

  function createGrid(iElement, iColumns, iOptions, type) {
    activeElement = iElement;
    allColumns = iColumns;
    columns = iColumns;
    options = iOptions;
    activeGrid = type;

    for (var i in columns) {
      if (columns[i].filterable) {
        if (columns[i].id === '_checkbox_selector') {
          columnFilters[columns[i].id] = '';
          columns[i].currentOperator = '][';
        } else {
          columnFilters[columns[i].id] = '';
          columns[i].currentOperator = '=';
        }
      }
    }

    return new Promise((resolve) => {
      utilService.elementPromise(activeElement).then(() => {
        init();
        resolve(grid);
      });
    });

    function init() {
      setHeader();

      const groupItemMetadataProvider =
        new Slick.Data.GroupItemMetadataProvider();

      dataView = new Slick.Data.DataView({
        groupItemMetadataProvider,
        inlineFilters: false,
      });

      grid = new Slick.Grid(activeElement, dataView, columns, options);

      grid.registerPlugin(groupItemMetadataProvider);
      grid.setSelectionModel(new Slick.CellSelectionModel());

      const selectColumnButtonsPlugin = new Slick.Plugins.SelectColumnButtons();
      selectColumnButtonsPlugin.onCommand.subscribe(function (
        e,
        { column, command },
      ) {
        if (command == 'SELECT') {
          e.stopPropagation();
          var columnIndex = -1;
          for (var i in columns) {
            if (columns[i].field === column.field) {
              columnIndex = i;
            }
          }
          if (columnIndex > -1) {
            var newRange = new Slick.Range(
              0,
              columnIndex,
              dataView.getLength() - 1,
              columnIndex,
            );
            grid.getSelectionModel().setSelectedRanges([newRange]);
            grid.invalidate();
          }
        }
      });
      grid.registerPlugin(selectColumnButtonsPlugin);

      // set keyboard focus on the grid
      grid.getCanvasNode().focus();

      grid.setHeaderRowVisibility(false);

      grid.onSelectedRowsChanged.subscribe(() => {
        if (!isInEditMode) {
          setSelectedGridRowIds();
        }
      });

      setContextmenu();
      setOptionalEvents();
      setDataviewEvents();
      setSortEvent();
      setMouseEvents();

      grid.onValidationError.subscribe(function (e, args) {
        // handle validation errors originating from the CompositeEditor
        if (args.editor && args.editor instanceof Slick.CompositeEditor) {
          var err;
          var idx = args.validationResults.errors.length;
          while (idx--) {
            err = args.validationResults.errors[idx];
            $(err.container)
              .stop(true, true)
              .effect('highlight', { color: 'red' }, 3000);
            toastr.error(
              err.container.selector.split('=')[1].replace(']', '') +
                ': ' +
                err.msg,
            );
          }
        }
      });
    }
  }

  function destroyGrid() {
    clearSelection();
    clearFilter();
    grid.destroy();
    grid = null;
    activeGrid = 'NONE';
    clearInterval(klicInterval);
  }

  function updateOnderzoeken(locaties, transform) {
    if (transform) {
      locaties = locaties.map(transform);
    }
    var locations = [];
    var ids = [];
    var j = 0;
    for (var i in locaties) {
      if (!ids.includes(locaties[i].id)) {
        var d = (locations[j] = {});
        ids.push(locaties[i].id);
        d['id'] = locaties[i].id;
        for (var k in allColumns) {
          if (allColumns[k].field === 'onderzoekstatusdatum') {
            d[allColumns[k].field] = Date.now();
          } else {
            d[allColumns[k].field] =
              locaties[i][allColumns[k].field] === undefined
                ? null
                : locaties[i][allColumns[k].field];
          }
        }
        d['_checkbox_selector'] = locaties[i].id;
        j++;
      }
    }
    updateData(locations);
  }

  function highlightRow(id) {
    const row = dataView.getRowById(id);
    if (row) {
      grid.setSelectedRows([row]);
      grid.scrollRowToTop(row);
      // Next line is for when the grid just opened, and doesn't yet
      // exactly know where it's displayed.
      // Previous line is for when the grid was already there and it
      // feels awkward to have to wait for the scroll.
      setTimeout(() => grid.scrollRowToTop(row), 1000);
    }
  }

  function invalidate() {
    grid.invalidate();
  }

  function onderzoekenPF(model) {
    if (model.filter.KAART) {
      return grid.project.onderzoeken.filter((o) =>
        model.filter.KAART.intersectsCoordinate([o.x, o.y]),
      );
    } else {
      return grid.project.onderzoeken;
    }
  }

  function fetchOnderzoeken(model, metadata, force) {
    if (!grid) {
      return;
    }
    const transform = metadata ? gridMetadataTransform : undefined;
    const getOnderzoeken = 'getOnderzoeken' + (metadata ? 'Metadata' : '');
    grid.project = grid.project || {};
    if (model.modus().startsWith('P')) {
      if (force || grid.project.id !== model.project.id) {
        userAccountService[getOnderzoeken + 'BySubproject'](
          model.project.id,
        ).then((onderzoeken) => {
          grid.project = {
            id: model.project.id,
            onderzoeken: onderzoeken,
            filterFingerprint: model.filter.fingerprint,
          };
          updateOnderzoeken(onderzoekenPF(model), transform);
        });
      } else if (grid.project.filterFingerprint !== model.filter.fingerprint) {
        grid.project.filterFingerprint = model.filter.fingerprint;
        updateOnderzoeken(onderzoekenPF(model), transform);
      }
    } else {
      delete grid.project;
      if (model.filter.KAART) {
        const wkt = wktFormat.writeGeometry(model.filter.KAART);
        userAccountService[getOnderzoeken + 'ByGeometry'](wkt).then(
          (onderzoeken) => updateOnderzoeken(onderzoeken, transform),
        );
      } else {
        const ids = model.getFeatures().map((f) => f.get('id'));
        if (ids.length) {
          userAccountService[getOnderzoeken + 'ById'](ids).then((onderzoeken) =>
            updateOnderzoeken(onderzoeken, transform),
          );
        } else {
          updateData([]);
        }
      }
    }
  }

  function getData(model) {
    const force = !model; // vanuit prikken nieuw onderzoek
    model = model || modelService.getModel();
    const metadata = false;
    fetchOnderzoeken(model, metadata, force);
  }

  function getMetaData(model) {
    const metadata = true;
    fetchOnderzoeken(model, metadata);
  }

  $rootScope.$on('model-project', () => clearFilter());

  function getKlicNetwerken(model, callback) {
    if (!grid) {
      return;
    }
    if (model.project) {
      const url = 'web_anon_net?tag=eq.' + model.project.subprojectnr;
      userAccountService.klicav.api.get(url, (err, json) => {
        if (!err) {
          json.forEach((e, i) => {
            // "Each data element must implement a unique 'id' property"
            e.id = e.id || i;
            if (e.bestand && !e.bestand.startsWith('/klicav/dynamic/')) {
              e.bestand = '/klicav/dynamic/leveringen' + e.bestand;
            }
            const geom = geomFromText(e.polygoon, {
              dataProjection: 'EPSG:28992',
            });
            e.bbox = fromExtent(geom.getExtent());
            e.x = geom.getFirstCoordinate()[0];
            e.y = geom.getFirstCoordinate()[1];
            e.hull = (() => {
              geom.scale(1.5);
              return geom;
            })();
          });
          updateData(json);
        }
        callback(err, json);
      });
    } else {
      updateData([]);
    }
  }

  const wktFormat = new WKT();

  function geomFromText(wkt, options = {}) {
    options.dataProjection = options.dataProjection || 'EPSG:4326'; // KML
    options.featureProjection =
      options.featureProjection || window.map.getView().getProjection();
    const geometry = wktFormat.readGeometry(wkt, options);
    return geometry;
  }

  function getPlantekeningen(model) {
    if (!grid) {
      return Promise.reject('no grid');
    }
    if (model.project) {
      if (model.grid.PLANTEKENINGEN) {
        return userAccountService
          .getPlantekeningen(model.project.id)
          .then((data) => load(data, model.exclude.PLAN));
      } else if (model.grid.PROJECTKAARTEN) {
        return userAccountService
          .getProjectkaarten(model.project.id)
          .then((data) => load(data, model.exclude.PROJECT));
      }
    } else {
      updateData([]);
      return Promise.reject('no project');
    }

    function load(data, exclude) {
      exclude = exclude || [];
      data.forEach((item) => {
        item.tonen = !exclude.includes(item.id);
        item.bbox = geomFromText(item.bbox);
        item.hull = geomFromText(item.hull);
        item.point = geomFromText(item.point);
        const coordinates = item.point.getCoordinates();
        item.x = coordinates[0];
        item.y = coordinates[1];
      });
      updateData(data);
      return data;
    }
  }

  function getLogboek(model) {
    if (!grid) {
      return Promise.reject('no grid');
    }
    if (model.project) {
      return userAccountService
        .getProjectLogboek(model.project.id)
        .then((data) => {
          updateData(data);
          return data;
        });
    } else {
      updateData([]);
      return Promise.reject('no project');
    }
  }

  function updateData(locations) {
    if (grid) {
      dataView.beginUpdate();
      dataView.setItems(locations);
      dataView.setFilterArgs({
        columnFilters: columnFilters,
        grid: grid,
      });
      dataView.setFilter(filter);
      dataView.endUpdate();
      grid.invalidate();
      grid.render();
      if (gridHasColumn('naam')) {
        gridSorter('naam', true);
        grid.setSortColumn('naam', true);
      }
      setGridRowIds();
    }
  }

  function gridHasColumn(columnId) {
    var c = columns.filter(getColumnById, { id: columnId });
    return c.length > 0 ? true : false;
  }

  function resizeGrid() {
    if (grid != null) {
      grid.resizeCanvas();
    }
  }

  function getGrid() {
    return grid;
  }

  function getDataview() {
    return dataView;
  }

  function setHeader() {
    $('.grid-header .ui-icon')
      .addClass('ui-state-default ui-corner-all')
      .mouseover(function (e) {
        $(e.target).addClass('ui-state-hover');
      })
      .mouseout(function (e) {
        $(e.target).removeClass('ui-state-hover');
      });
  }

  function setColumns(newColumns) {
    //adSelectorColumn(newColumns);
    columns = newColumns;
    grid.setColumns(columns);
    grid.invalidate();
    grid.render();
  }

  let filter;
  function setFilter() {
    filter = function filter(item, { columnFilters }) {
      if (activeGrid === 'ONDERZOEKEN' || activeGrid === 'METADATA') {
        if (selectedView.id === 'Sonderingen' && !item['cpt']) {
          return false;
        }
        if (selectedView.id === 'Boringen' && !item['bhrgt']) {
          return false;
        }
        if (selectedView.id === 'Labparameters' && !item['lababcde']) {
          return false;
        }
        if (selectedView.id === 'Labtests' && !item['labmdta']) {
          return false;
        }
        if (selectedView.id === 'Peilbuizen' && !item['gmw']) {
          return false;
        }
        for (const [column, filterExpression] of Object.entries(
          columnFilters,
        )) {
          if (filterExpression.length) {
            const columnDef = getColumn(column);
            const { field, formatter, currentOperator } = columnDef;
            const value = item[field];
            const cellText = (() => {
              let result;
              if (formatter && formatter.name !== 'Selector') {
                result = formatter(null, null, value, columnDef, item);
              }
              return result || '' + value;
            })();
            // eslint-disable-next-line no-inner-declarations
            function any() {
              for (const part of filterExpression.split(';')) {
                if (part.length && cellText.indexOf(part) >= 0) {
                  return true;
                }
              }
            }
            // eslint-disable-next-line no-inner-declarations
            function compare() {
              let left = cellText;
              let right = filterExpression;
              if (formatter && formatter.name.startsWith('Date')) {
                left = new Date(value);
                right = new Date(filterExpression);
              } else if (formatter && formatter.name.startsWith('Float')) {
                left = value;
                right = parseFloat(filterExpression);
              }
              switch (currentOperator) {
                case '>':
                  return left > right;
                case '<':
                  return left < right;
              }
            }
            // eslint-disable-next-line no-inner-declarations
            function pass() {
              switch (currentOperator) {
                case '[]':
                  return any();
                case '][':
                  return !any();
                case '>':
                  return compare();
                case '<':
                  return compare();
                case '=':
                  return cellText && cellText.indexOf(filterExpression) >= 0;
              }
            }
            if (!pass()) {
              return false;
            }
          }
        }
      }
      return true;
    };

    const headerrow = grid.getHeaderRow(),
      headers = $(activeElement).find('.slick-header-column');

    function filterInputTemplate(operator) {
      return (
        '<button' +
        " style='width:22px;height:22px;margin-right:7px;padding:1px;line-height:100%;'" +
        '>' +
        operator +
        '</button>' +
        "<input type='text'" +
        " style='display:inline-block;width:calc(100% - 35px);height:auto;padding:0;'" +
        '>'
      );
    }

    function getColumn(columnId) {
      return grid.getColumns()[grid.getColumnIndex(columnId)];
    }

    for (var i = 0; i < headerrow.children.length; i++) {
      const columnFilter = headerrow.children[i];
      let column = columns[i];
      let colid = null;
      const header = headers[i];
      if (header != null && header != undefined) {
        const headerid = header.getAttribute('id');
        colid = headerid.replace(/slickgrid_[0-9]*/, '');
      }
      if (colid != null) {
        column = getColumn(colid);
      }
      //if (column != undefined) {
      if (column.filterable && column.id !== '_checkbox_selector') {
        $(filterInputTemplate(column.currentOperator))
          .data('columnId', column.id)
          .val(columnFilters[column.id])
          .appendTo(columnFilter);
      }
      //}
      if (columns[i].assignable) {
        $(
          "<div><span id='assigned_" +
            columns[i].id +
            "'>" +
            columns[i].assigned +
            '</span> / ' +
            columns[i].maxAssign +
            '</div>',
        )
          .data('columnId', columns[i].id)
          .val(columnFilters[columns[i].id])
          .appendTo(columnFilter);
      }
    }
    $('#grid-extraFilter').on('click', function () {
      resetExtraFilter();
    });
    $(grid.getHeaderRow()).on('keyup', ':input', function (e) {
      const columnId = $(this).data('columnId');
      if (columnId) {
        if (getColumn(columnId).currentOperator != '=' && e.key === ';') {
          // just wait for the next char typed after the ";"
          return;
        }
        columnFilters[columnId] = $.trim($(this).val());
        dataView.setFilterArgs({
          columnFilters: columnFilters,
          grid: grid,
        });
        dataView.refresh();
        setGridRowIds();
      }
    });
    $(grid.getHeaderRow()).on('click', 'button', function () {
      const columnId = $(this).data('columnId');
      if (columnId) {
        const operators = ['=', '[]', '][', '>', '<'],
          column = getColumn(columnId),
          columnHeaderRow = grid.getHeaderRowColumn(
            grid.getColumnIndex(columnId),
          ),
          oldIndex = operators.indexOf(column.currentOperator),
          newOperator =
            operators[oldIndex === operators.length - 1 ? 0 : oldIndex + 1];
        column.currentOperator = newOperator;
        columnHeaderRow.innerHTML = '';
        $(filterInputTemplate(newOperator))
          .data('columnId', columnId)
          .val(columnFilters[columnId])
          .appendTo(columnHeaderRow);
        dataView.refresh();
        setGridRowIds();
      }
    });
  }

  var keepFilter = false;

  function setGridRowIds(clear) {
    if (!dataView) {
      return;
    } else if (clear) {
      update();
    } else {
      const filteredItems = dataView.getFilteredItems(),
        total = dataView.getItems().length;
      // modelService.getModel().grid.METADATA om ook de kaart bij te werken als er geen boringen-metadata is en de sonderingen-view actief is (of andersom)
      if (
        modelService.getModel().grid.METADATA ||
        filteredItems.length < total
      ) {
        keepFilter = false;
        update(filteredItems.map((item) => item.id));
      } else {
        keepFilter = filteredItems.length === total;
        update();
      }
    }

    function update(value) {
      setSelectedGridRowIds([]);
      modelService.update('model-filter-grid', value);
    }
  }

  function setSelectedGridRowIds(selectedRows) {
    const model = modelService.getModel();
    if (!model.tools.has('POINTEDITOR')) {
      selectedRows = selectedRows || grid.getSelectedRows();
      modelService.update(
        'model-selectie',
        selectedRows.map((row) => dataView.getItem(row).id),
      );
    }
  }

  $rootScope.$on('model-filter-grid', (_, model) => {
    if (!model.filter.GRID) {
      if (!keepFilter) {
        clearFilter();
      }
      keepFilter = false;
    }
  });

  function clearFilter() {
    if (!grid) {
      return;
    }
    $('.slick-headerrow-column').children().val('');
    unselectedRowIds = [];
    for (var columnId in columnFilters) {
      columnFilters[columnId] = '';
    }
    resetExtraFilter();
  }

  function resetExtraFilter() {
    unselectedRowIds = [];
    columnFilters['_checkbox_selector'] = '';
    dataView.setFilterArgs({
      columnFilters: columnFilters,
      grid: grid,
    });
    dataView.refresh();
    setGridRowIds();
    const gridExtraFilter = $('#grid-extraFilter');
    gridExtraFilter.removeClass('on');
    gridExtraFilter.removeAttr('title');
  }

  function clearSelection() {
    // this will fire onSelectedRowsChanged:
    grid.setSelectedRows([]);
  }

  // function setGridMenu() {
  //   gridMenuControl = new Slick.Controls.GridMenu(columns, grid, options);
  //   gridMenuControl.onCommand.subscribe(function (e, args) {
  //     switch (args.command) {
  //       case "toggle-filter":
  //         grid.setHeaderRowVisibility(!grid.getOptions().showHeaderRow);
  //         break;
  //       case "toggle-toppanel":
  //         grid.setTopPanelVisibility(!grid.getOptions().showTopPanel);
  //         break;
  //       case "filter-clean":
  //         $('.slick-headerrow-column').children().val('');
  //         for (var columnId in columnFilters) {
  //           if (columnId === "_checkbox_selector") {
  //             columnFilters[columnId] = "true";
  //           } else {
  //             columnFilters[columnId] = "";
  //           }
  //         }
  //         var items = dataView.getItems();
  //         for (var i in items) {
  //           items[i]["_checkbox_selector"] = true;
  //         }
  //         dataView.refresh();
  //         break;
  //       case "groupby-onderzoekstype":
  //         groupByOnderzoekstype();
  //         dataView.collapseAllGroups();
  //         break;
  //       case "groupby-meetpuntstatus":
  //         groupByMeetpuntStatus();
  //         dataView.collapseAllGroups();
  //         break;
  //       case "groupby-onderzoekstatus":
  //         groupByOnderzoekStatus();
  //         dataView.collapseAllGroups();
  //         break;
  //       case "groupby-clear":
  //         dataView.setGrouping([]);
  //         break;
  //       case "groupby-collapse":
  //         dataView.collapseAllGroups();
  //         break;
  //       case "groupby-expand":
  //         dataView.expandAllGroups();
  //         break;
  //       case "selection-range":
  //         grid.setSelectionModel(new Slick.CellSelectionModel());
  //         dataView.syncGridSelection(grid, true, true);
  //         break;
  //       case "selection-row":
  //         grid.setSelectionModel(new Slick.RowSelectionModel({ selectActiveRow: false }));
  //         dataView.syncGridSelection(grid, true, true);
  //         break;
  //     }
  //   });
  //   gridMenuControl.onColumnsChanged.subscribe(function (e, args) {
  //     console.log('Columns changed via the menu');
  //   });
  //   gridMenuControl.onMenuClose.subscribe(function (e, args) {
  //     //console.log('Menu is closing');
  //   });
  // }

  function setMouseEvents() {
    grid.onClick.subscribe(function (e, { grid, cell, row }) {
      e.preventDefault();
      if ('_checkbox_selector' === grid.getColumns()[cell].id) {
        var ids = dataView.mapRowsToIds([row]);
        unselectedRowIds.push(ids[0]);
        columnFilters['_checkbox_selector'] = unselectedRowIds.join(';');
        dataView.setFilterArgs({
          columnFilters: columnFilters,
          grid: grid,
        });
        dataView.refresh();
        setGridRowIds();
        const gridExtraFilter = $('#grid-extraFilter');
        gridExtraFilter.addClass('on');
        gridExtraFilter.attr('title', 'Verborgen onderzoeken weer tonen');
      }
    });
    //grid.onDblClick.subscribe(function (e) {
    // Reageert niet
    //    e.preventDefault();
    //    alert("Double Click");
    //});
    grid.onMouseEnter.subscribe(function (e, args) {
      // Bij enter cell
      e.preventDefault();
      const cell = args.grid.getCellFromEvent(e);
      const column = args.grid.getColumns()[cell.cell];
      const item = args.grid.getCellNode(cell.row, cell.cell);
      item.setAttribute('data-tooltip', "{ 'class': 'gridcell-tooltip' }");
      const row = dataView.getItem(cell.row);
      const tooltip = column.showTooltip
        ? row.naam + ': ' + item.innerHTML
        : row.naam;
      item.setAttribute('title', tooltip);
    });
    grid.onMouseLeave.subscribe(function (e) {
      // Bij verlaten cell
      e.preventDefault();
    });
    //grid.onKeyDown.subscribe(function (e) {
    // Bij toetsenbord key
    //    e.preventDefault();
    //    alert("Key Down");
    //});
  }

  function setContextmenu() {
    grid.onContextMenu.subscribe(function (e) {
      console.log('onContextmenu (right mouse clicked)');
      const model = modelService.getModel();
      if (!(window.INTERNAL_USER && model.grid.ONDERZOEKEN)) {
        return;
      }
      e.preventDefault();
      const editAllowed = model.modus().startsWith('P');
      if (!editAllowed) {
        alert('Wijzigingen zijn alleen toegestaan in P- en PF-modus.');
        return;
      }
      let selectionModel = grid.getSelectionModel();
      let ranges = selectionModel.getSelectedRanges();
      if (ranges.length > 0) {
        if (ranges.length == 1) {
          if (ranges[0].isSingleCell()) {
            const cell = grid.getCellFromEvent(e);
            grid.setSelectedRows([cell.row]);
            grid.setActiveCell(cell.row, cell.cell);
            selectionModel = grid.getSelectionModel();
            ranges = selectionModel.getSelectedRanges();
            editRange = ranges[0];
            openDetails(editRange, e.pageX, e.pageY);
            //openDetailsWhenUpdated(editRange, e.pageX, e.pageY);
            //} else if (ranges[0].isSingleRow()) {
            //    // edit a row with compositeEditor
            //    // var selectedCell = grid.getCellFromEvent(e);
            //    // grid.setActiveCell(selectedCell.row, selectedCell.cell);
            //    editRange = ranges[0];
            //    openDetails(editRange, e.pageX, e.pageY);
          } else {
            // edit all cells in all rows from range with compositEditor
            editRange = ranges[0];
            var showAlert = false;
            if (editRange.fromRow != editRange.toRow) {
              for (var i = editRange.fromCell; i < editRange.toCell + 1; i++) {
                if (!columns[i].multiEdit) {
                  showAlert = true;
                }
              }
            }
            if (showAlert) {
              alert('Not multiEdit');
            } else {
              openDetails(editRange, e.pageX, e.pageY);
              //openDetailsWhenUpdated(editRange, e.pageX, e.pageY);
            }
          }
        } else {
          alert('Aantal ranges: ' + ranges.length);
        }
      } else {
        var cell = grid.getCellFromEvent(e);
        grid.setSelectedRows([cell.row]);
        grid.setActiveCell(cell.row, cell.cell);
        selectionModel = grid.getSelectionModel();
        ranges = selectionModel.getSelectedRanges();
        editRange = ranges[0];
        openDetails(editRange, e.pageX, e.pageY);
        //openDetailsWhenUpdated(editRange, e.pageX, e.pageY);
      }
    });
    $('#contextmenu').click(function (e) {
      if (!$(e.target).is('li')) {
        return;
      }
      if (!grid.getEditorLock().commitCurrentEdit()) {
        return;
      }
      //var row = $(this).data("row");
      //data[row].priority = $(e.target).attr("data");
      //grid.updateRow(row);
    });
  }

  function setOptionalEvents() {
    grid.onCellChange.subscribe(function (e, { item }) {
      dataView.updateItem(item.id, item);
    });
    grid.onKeyDown.subscribe(function (e) {
      // select all rows on ctrl-a
      if (e.which != 65 || !e.ctrlKey) {
        return false;
      }
      var rows = [];
      for (var i = 0; i < dataView.getLength(); i++) {
        rows.push(i);
      }
      grid.setSelectedRows(rows);
      e.preventDefault();
    });
    grid.onColumnsReordered.subscribe(function () {
      console.log('column reordered');
      setFilter();
    });
    grid.onColumnsResized.subscribe(function (e, { triggeredByColumn }) {
      console.log('column resized: ' + triggeredByColumn);
    });
    grid.onCellChange.subscribe(function (e, { row, cell }) {
      if (
        editRange &&
        editRange != null &&
        row == editRange.fromRow &&
        cell == editRange.fromCell
      ) {
        var sourceValues = [];
        for (var i = editRange.fromCell; i <= editRange.toCell; i++) {
          var rowData = dataView.getItem(editRange.fromRow);
          var columnId = columns[i].id;
          sourceValues.push({ id: columnId, value: rowData[columnId] });
        }
        dataView.beginUpdate();
        for (var ii = editRange.fromRow; ii <= editRange.toRow; ii++) {
          const item = dataView.getItem(ii);
          for (var j in sourceValues) {
            item[sourceValues[j].id] = sourceValues[j].value;
          }
          dataView.updateItem(item.id, item);
        }
        dataView.endUpdate();
      } else {
        // var cols = grid.getColumns();
        // var col = cols[args.cell];
        //if (isWerkzaamheid(col)) {
        //    add = item[col.id];
        //    if (add) {
        //        userAccountService.addWerkzaamheid({ onderzoekstypeId: item.id, werkzaamheidstypeId: col.id, constraint: "" })
        //            .then(function (response) {
        //                alert("Wijziging opgeslagen in database");
        //                //deferred.resolve(response);
        //            }, function (error, status) {
        //                alert("Wijziging niet opgeslagen in database");
        //                //deferred.reject(response.status);
        //            });
        //    } else {
        //        userAccountService.deleteWerkzaamheid({ onderzoekstypeId: item.id, werkzaamheidstypeId: col.id, constraint: "" })
        //            .then(function (response) {
        //                alert("Wijziging opgeslagen in database");
        //                //deferred.resolve(response);
        //            }, function (error, status) {
        //                alert("Wijziging niet opgeslagen in database");
        //                //deferred.reject(response.status);
        //            });
        //    }
        //}
      }
      setGridRowIds();
    });
  }

  function setDataviewEvents() {
    dataView.onRowCountChanged.subscribe(function () {
      if (grid != null) {
        grid.updateRowCount();
        grid.render();
      }
    });
    dataView.onRowsChanged.subscribe(function (e, args) {
      if (grid != null) {
        grid.invalidateRows(args.rows);
        grid.render();
      }
    });
    //dataView.onPagingInfoChanged.subscribe(function (e, pagingInfo) {
    //    grid.updatePagingStatusFromView(pagingInfo);
    //});
  }

  function setSortEvent() {
    grid.onSort.subscribe(function (e, args) {
      gridSorter(args.sortCol.field, args.sortAsc);
    });
  }

  function gridSorter(field, sortAsc) {
    //var refdata = $rootScope.refdata;
    //function volgorde(item) {
    //    var naam = item.naam.toUpperCase();
    //    var index = naam.search(/\d/);
    //    var tail = naam.substring(index);
    //    var pot = refdata.primair_onderzoekstype[item.primair_onderzoekstype];
    //    var volgorde = pot ? pot.volgorde : '';
    //    return item.projectnr + volgorde + tail;
    //}
    //function sortNaam(a, b) {
    //    if (a.projectnr === b.projectnr) {
    //        return (volgorde(a) > volgorde(b)) ? 1 : -1;
    //    } else {
    //        return (a.projectnr > b.projectnr) ? 1 : -1;
    //    }
    //}
    var comparer = function (a, b) {
      if (field === 'naam') {
        return utilService.OnderzoeknaamComparer(a, b);
      } else {
        if (a[field] == null) {
          return 1;
        }
        if (b[field] == null) {
          return -1;
        }

        return a[field] > b[field] ? 1 : -1;
      }
    };
    dataView.sort(comparer, sortAsc);
  }

  function openDetails(range, mouseX, mouseY) {
    if (
      grid.getEditorLock().isActive() &&
      !grid.getEditorLock().commitCurrentEdit()
    ) {
      return;
    }

    // FIX: Edit popup loopt vast als de geselecteerde range begint met de kolom veldwekdatum (geen idee waarom)
    // Dus moet in dat geval de range 1 kolom kleiner (verwijder de veldwerkdatum-kolom)
    var idx = grid.getColumnIndex('veldwerkdatum');
    if (range.fromCell == idx) {
      range.fromCell++;
    }

    var rangeColumns = columns.filter(isInRange);
    var editableColumns = rangeColumns.filter(isEditable);
    if (isIncluded('onderzoekstatus')) {
      if (
        !isIncluded('onderzoekstatusopmerking') &&
        range.fromRow == range.toRow
      ) {
        editableColumns.push(getFromAllColumns('onderzoekstatusopmerking'));
      }
      editableColumns.push(getFromAllColumns('onderzoekstatusdatum'));
    }
    if (editableColumns.length < 1) {
      return;
    }

    isInEditMode = true;

    var shieldHtml =
      "<div style='display: block;position: fixed;top: 0; bottom: 0; left: 0; right: 0;background-color: black;opacity: 0.5;z-index: 6000;text-align: center;'></div >";
    var $shield = $(shieldHtml);
    $shield.appendTo('#grid');

    var $modal = $("<div class='item-details-form'></div>");
    grid.setActiveCell(range.fromRow, range.fromCell);

    $modal = $('#itemDetailsTemplate')
      .tmpl({
        context: grid.getDataItem(range.fromRow),
        columns: editableColumns,
        canDelete: range.fromRow == range.toRow ? true : false,
        canShowInfo: true, // (range.fromRow == range.toRow) ? true : false,
        showInfo: false,
      })
      .appendTo('body');

    var oldItems = [];
    for (var i = range.fromRow; i < range.toRow + 1; i++) {
      oldItems.push($.extend(true, {}, dataView.getItem(i)));
    }

    function setGridEditable(editable) {
      const suppressRender = undefined;
      // prevent having the grid list all columns:
      const suppressColumnSet = true;
      const suppressSetOverflow = undefined;
      grid.setOptions(
        { editable },
        suppressRender,
        suppressColumnSet,
        suppressSetOverflow,
      );
    }

    function isFloat(fieldName) {
      const columns = grid.getColumns();
      return !!columns.find(
        ({ field, editor }) =>
          field === fieldName && editor === Slick.Editors.Float,
      );
    }

    function save() {
      var succeeded = grid.getEditController().commitCurrentEdit();

      if (succeeded) {
        setGridEditable(false);

        var onderzoeken = [];
        for (var ii = range.fromRow; ii < range.toRow + 1; ii++) {
          var onderzoek = dataView.getItem(ii);
          //if (i == range.fromRow) {
          //    opmerking = onderzoek.onderzoekstatusopmerking;
          //} else {
          //    onderzoek.onderzoekstatusopmerking = opmerking;
          //}
          const changes = utilService.changesById(onderzoek, oldItems);
          if (changes.x || changes.y) {
            changes.xymethode = 'GEOREF';
            if (changes.z === undefined) {
              changes.z = null;
            }
          }
          if (
            changes.onderzoekstatus &&
            changes.onderzoekstatus === '99-N_UITV'
          ) {
            const oldItem = oldItems.find(({ id }) => id === changes.id);
            if (oldItem.xystatus === 'XYZ_Veldwerk') {
              changes.xystatus = 'XYZ_Uitgezet';
            }
          }
          // convert any empty-string float values to null
          for (const [field, value] of Object.entries(changes)) {
            if (value === '' && isFloat(field)) {
              changes[field] = null;
            }
          }
          onderzoeken.push(changes);
        }
        var t = dataView.getItem(range.fromRow);
        var tijdstip;
        try {
          tijdstip = new Date(t['onderzoekstatusdatum']); // new Date(t["onderzoeksdatum"]);
        } catch (ex) {
          tijdstip = new Date.now();
        }
        userAccountService.putOnderzoeken(onderzoeken, tijdstip).then(
          function (updatedOnderzoeken) {
            toastr.success('Wijziging geslaagd');
            mapService.refresh(['Meetpunten', 'Meetpunt labels']);

            dataView.beginUpdate();
            for (const onderzoek of updatedOnderzoeken) {
              var values = {};
              values.id = onderzoek.id;
              for (const { field } of allColumns) {
                if (field === 'onderzoekstatusdatum') {
                  values[field] = Date.now();
                } else {
                  values[field] = onderzoek[field];
                }
              }
              values._checkbox_selector = onderzoek.id;
              dataView.updateItem(values.id, values);
            }
            dataView.endUpdate();
            grid.invalidate();
            //grid.render();
            isInEditMode = false;
            clearRange();
          },
          function () {
            dataView.beginUpdate();
            for (var i in oldItems) {
              dataView.updateItem(oldItems[i].id, oldItems[i]);
            }
            dataView.endUpdate();
            grid.invalidate();
            //grid.render();
            isInEditMode = false;
            clearRange();
          },
        );
      }
    }

    function clearRange() {
      grid.setSelectedRows([]);
      grid.resetActiveCell();
    }

    function cancel() {
      grid.getEditController().cancelCurrentEdit();
      clearRange();
      setGridEditable(false);
      isInEditMode = false;
      grid.render();
      $shield.remove();
      if ($modal) {
        $modal.remove();
      }
    }

    $modal.keydown(function (e) {
      if (e.which == $.ui.keyCode.ENTER) {
        save();
        e.stopPropagation();
        e.preventDefault();
      } else if (e.which == $.ui.keyCode.ESCAPE) {
        cancel();
        e.stopPropagation();
        e.preventDefault();
      }
    });

    $modal.find('[data-action=save]').click(function () {
      save();
    });
    $modal.find('[data-action=cancel]').click(function () {
      cancel();
    });
    $modal.find('[data-action=info]').click(function () {
      var ids = [];
      for (var i = range.fromRow; i <= range.toRow; i++) {
        ids.push(dataView.getItem(i).id);
      }
      userAccountService.getHistory(ids).then(function (data) {
        $mdDialog.show({
          controller: infoDialogController,
          templateUrl: $sce.trustAsResourceUrl(infoHtml),
          parent: angular.element(document.body),
          clickOutsideToClose: false,
          escapeToClose: true,
          locals: { data },
        });
      });
    });
    //$modal.find("[data-action=longtext]").click(function () {
    //    alert("EDIT LONGTEXT");
    //});

    function infoDialogController($scope, $mdDialog, data) {
      $scope.closeSvg = closeSvg;

      $scope.meetpunten = data;

      $scope.cancel = function () {
        $mdDialog.hide($scope.items);
      };
    }
    infoDialogController.$inject = ['$scope', '$mdDialog', 'data'];

    var containers = $.map(editableColumns, function (c) {
      return $modal.find('[data-editorid=' + c.id + ']');
    });
    var compositeEditor = new Slick.CompositeEditor(
      editableColumns,
      containers,
      {
        destroy: function () {
          isInEditMode = false;
          $modal.remove();
          $shield.remove();
          setTimeout(function () {
            setGridEditable(false);
          }, 500);
        },
      },
    );
    setGridEditable(true);
    grid.editActiveCell(compositeEditor);

    function isEditable(item) {
      return item.editor != null;
    }

    function isIncluded(columnId) {
      var filteredColumns = editableColumns.filter(function (c) {
        return c.id == columnId;
      });
      return filteredColumns.length > 0 ? true : false;
    }

    function isInRange(item) {
      return (
        grid.getColumnIndex(item.id) >= range.fromCell &&
        grid.getColumnIndex(item.id) <= range.toCell
      );
    }

    $('.item-details-form').css('max-height', 'calc(100% - 20px)');
    $('.item-details-form').css('overflow', 'auto');
    var height = editableColumns.length * 61 + 63;
    var windowHeight = $(window).height();
    var top = mouseY;
    if (height + 20 > windowHeight - mouseY) {
      top = mouseY - (height - (windowHeight - mouseY)) - 20;
    }
    $('.item-details-form').css('top', Math.max(0, top));
    var width = 444;
    var windowWidth = $(window).width();
    var left = mouseX;
    if (width + 20 > windowWidth - mouseX) {
      left = mouseX - (width - (windowWidth - mouseX)) - 20;
    }
    $('.item-details-form').css('left', Math.max(0, left));
  }

  function getRowsFromRange() {
    var rows = [];
    var ranges = grid.getSelectionModel().getSelectedRanges();
    if (ranges.length > 0) {
      var range = ranges[0];
      for (var i = range.fromRow; i <= range.toRow; i++) {
        rows.push({ id: i, item: dataView.getItem(i) });
      }
    }
    return rows;
  }

  function DeleteRows(rows) {
    var onderzoek = rows; //dataView.getItem(rows);
    userAccountService.delOnderzoek(onderzoek.id).then(
      function () {
        toastr.success('Wijziging geslaagd');
        setTimeout(function () {
          mapService.refresh(['Projecten', 'Meetpunten', 'Meetpunt labels']);
        }, 500);
        dataView.beginUpdate();
        dataView.deleteItem(onderzoek.id);
        dataView.endUpdate();
        grid.invalidate();
        grid.render();
        clearRange();
        setGridRowIds();
      },
      function () {
        clearRange();
        setGridRowIds();
      },
    );
  }

  function clearRange() {
    grid.setSelectedRows([]);
    grid.resetActiveCell();
  }

  function toggleFilterRow() {
    grid.setHeaderRowVisibility(!grid.getOptions().showHeaderRow);
  }

  function setSelectionMethod(method) {
    if (method == 'Cell') {
      grid.setSelectionModel(new Slick.CellSelectionModel());
    } else {
      grid.setSelectionModel(
        new Slick.RowSelectionModel({ selectActiveRow: false }),
      );
    }
    dataView.syncGridSelection(grid, true, true);
  }

  function getSelectedFeatures() {
    var selectedFeatures = [];
    selectedFeatures = dataView.getItems();
    console.log('aantal items: ' + selectedFeatures.length);
    return selectedFeatures;
  }

  function groupByOnderzoekstype() {
    dataView.setGrouping({
      getter: 'onderzoekstype',
      formatter: function (g) {
        return (
          'Onderzoekstype:  ' +
          g.value +
          ' (' +
          $rootScope.refdata.onderzoekstype[g.value].omschrijving +
          ') ' +
          "  <span style='color:green'>" +
          g.count +
          ' items</span>'
        );
      },
      aggregators: [],
      aggregateCollapsed: false,
      lazyTotalsCalculation: true,
    });
  }

  function groupByMeetpuntStatus() {
    dataView.setGrouping({
      getter: 'xystatus',
      formatter: function (g) {
        return (
          'Status meetpunt:  ' +
          g.value +
          "  <span style='color:green'>" +
          g.count +
          ' items</span>'
        );
      },
      aggregators: [
        //new Slick.Data.Aggregators.Avg("percentComplete"),
        //new Slick.Data.Aggregators.Sum("cost")
      ],
      aggregateCollapsed: false,
      lazyTotalsCalculation: true,
    });
  }

  function groupByOnderzoekStatus() {
    dataView.setGrouping({
      getter: 'onderzoekstatus',
      formatter: function (g) {
        return (
          'Status onderzoek:  ' +
          g.value +
          ' (' +
          $rootScope.refdata.onderzoekstatus[g.value].omschrijving +
          ') ' +
          "  <span style='color:green'>" +
          g.count +
          ' items</span>'
        );
      },
      aggregators: [
        //new Slick.Data.Aggregators.Avg("percentComplete"),
        //new Slick.Data.Aggregators.Sum("cost")
      ],
      aggregateCollapsed: false,
      lazyTotalsCalculation: true,
    });
  }

  let klicInterval;

  function groupByKlicmeldnummer() {
    clearInterval(klicInterval);
    klicInterval = setInterval(() => {
      document.querySelectorAll('.klic-visibility').forEach((span) => {
        const klicmeldnummer = span.getAttribute('klicmeldnummer');
        const icon = span.firstElementChild;
        icon.onclick =
          icon.onclick ||
          function () {
            let exclude = modelService.getModel().exclude.KLIC || [];
            if (exclude.includes(klicmeldnummer)) {
              exclude = exclude.filter((e) => e != klicmeldnummer);
              icon.innerHTML = 'visibility';
              icon.title = 'Klik om te verbergen op de kaart';
            } else {
              exclude.push(klicmeldnummer);
              icon.innerHTML = 'visibility_off';
              icon.title = 'Klik om te tonen op de kaart';
            }
            modelService.update('model-exclude-klic', exclude);
          };
      });
    }, 100);
    function visibility(klicmeldnummer) {
      const exclude = (modelService.getModel().exclude.KLIC || []).includes(
        klicmeldnummer,
      );
      const klicIcon = icon({
        icon: exclude ? 'visibility_off' : 'visibility',
        title: `Klik om te ${exclude ? 'tonen' : 'verbergen'} op de kaart`,
      });
      return `
        <span class="klic-visibility" klicmeldnummer="${klicmeldnummer}">
          ${klicIcon}
        </span>
      `;
    }
    dataView.setGrouping({
      getter: 'klicmeldnummer',
      formatter: function (g) {
        const row = g.rows[0];
        let tooOld = false;
        try {
          const datumParts = row.aanvraagdatum.split('-');
          const datum = new Date(
            parseInt(datumParts[0]),
            parseInt(datumParts[1]) - 1,
            parseInt(datumParts[2]),
          );
          const today = new Date();
          const exists =
            (today.getTime() - datum.getTime()) / (1000 * 60 * 60 * 24);
          tooOld = exists > 28 ? true : false;
          // eslint-disable-next-line no-empty
        } catch {}
        let format = visibility(g.value);
        const compleet = JSON.parse(row.indicatieleveringcompleet);
        let ok = compleet;
        if (ok) {
          for (const row of g.rows) {
            if (row.eisvoorzorgsmaatregel && !row.vrijgegeven) {
              ok = false;
              break;
            }
          }
        }
        format += `<span style='color: ${ok && !tooOld ? 'green' : 'red'};'>
                    <span title=''>
                        ${g.value},
                    </span>
                    <span title='Aanvraagdatum'>
                        ${row.aanvraagdatum},
                    </span>
                    <span title=''>
                        ${compleet ? 'compleet' : 'LET OP: DEELLEVERING'}
                        (${g.count})
                    </span>
                `;
        if (!ok) {
          format +=
            ' ' +
            icon({
              icon: 'warning',
              title: compleet ? 'EV nog vrijgeven' : 'Deellevering',
            });
        }
        if (tooOld) {
          format += ' ' + icon({ icon: 'alarm', title: 'Verlopen' });
        }
        format += '</span>';
        return format;
      },
      aggregators: [
        //new Slick.Data.Aggregators.Avg("percentComplete"),
        //new Slick.Data.Aggregators.Sum("cost")
      ],
      aggregateCollapsed: false,
      lazyTotalsCalculation: true,
    });
  }

  function setGrouping(grouping, options) {
    switch (grouping) {
      case 'onderzoekstype':
        groupByOnderzoekstype();
        break;
      case 'onderzoeksstatus':
        groupByOnderzoekStatus();
        break;
      case 'xystatus':
        groupByMeetpuntStatus();
        break;
      case 'klicmeldnummer':
        groupByKlicmeldnummer();
        break;
      case 'geen':
        dataView.setGrouping([]);
        break;
    }
    options = options || {};
    if (!options.expand) {
      dataView.collapseAllGroups();
    }
  }

  function collapseAllGroups() {
    dataView.collapseAllGroups();
  }

  function expandAllGroups() {
    dataView.expandAllGroups();
  }

  function Export2Excel(fileName, options) {
    options.includeHeaders = true;
    options.bookType = 'xlsx';
    Export2Csv(fileName, options);
  }

  function Export2Csv(fileName, options) {
    if (!options.columns) {
      fileName = `${selectedView.label}_${fileName}`;
    }
    const project = modelService.getModel().project;
    if (project) {
      fileName = `${project.subprojectnr}_${fileName}`;
    }

    const defaults = {
      columns: grid.getColumns(), // default: all columns in the current view
      excludeColumnIds: [],
      includeHeaders: false,
      separator: ',',
      bookType: 'csv',
      decimals: undefined,
    };
    options = Object.assign(defaults, options);
    const {
      columns,
      excludeColumnIds,
      includeHeaders,
      separator,
      bookType,
      decimals,
    } = options;

    const exportColumns = [];
    for (const column of columns) {
      if (!excludeColumnIds.includes(column.id)) {
        exportColumns.push(column);
      }
    }

    function escape(value) {
      if (
        bookType === 'csv' &&
        typeof value === 'string' &&
        value.includes(separator)
      ) {
        value = `"${value}"`;
      }
      return value;
    }
    const decimalComma = (0.1).toLocaleString()[1] === ',' && separator !== ',';

    const arrayOfArrays = getDataRows(exportColumns).map((row) => {
      const values = Object.values(row).map((value) => {
        let result = value;
        if (typeof value === 'number') {
          let localeOptions;
          if (decimals) {
            localeOptions = {
              minimumFractionDigits: decimals,
              maximumFractionDigits: decimals,
            };
            result = value.toFixed(decimals);
          }
          if (decimalComma) {
            result = value.toLocaleString(undefined, localeOptions);
            result = result.replaceAll('.', '');
          }
        }
        return escape(result);
      });
      return values;
    });

    if (includeHeaders) {
      const headers = exportColumns.map((column) => escape(column.name));
      arrayOfArrays.unshift(headers);
    }

    const worksheet = XLSX.utils.aoa_to_sheet(arrayOfArrays);
    if (bookType === 'xlsx') {
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Blad1');
      XLSX.writeFile(workbook, fileName, { bookType });
    } else {
      // FieldSeparator not settable through XLSX.writeFile :-(
      const bits = XLSX.utils.sheet_to_csv(worksheet, { FS: separator });
      downloadLocalData(fileName, bits);
    }
  }

  function getDataRows(columns) {
    const items = dataView.getFilteredItems();
    const rows = items.map((item) => {
      const row = {};
      for (const { field, dataSource } of columns) {
        let value = item[field];
        if (value === undefined) {
          value = null;
        }
        if (dataSource) {
          const list = dataSource(item);
          const entry = list[value];
          if (entry) {
            const { omschrijving } = entry;
            if (omschrijving !== undefined) {
              value = omschrijving;
            }
          }
        }
        row[field] = value;
      }
      return row;
    });
    return rows;
  }

  function downloadLocalData(
    name,
    bitsOrBlob,
    type = 'text/csv;charset=utf-8',
  ) {
    const blob =
      bitsOrBlob instanceof Blob
        ? bitsOrBlob
        : new Blob([bitsOrBlob], { type });
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = name;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  function round(number, digits) {
    if (number == null) {
      return 'NULL';
    } else {
      var roundedNumber =
        Math.round(Math.abs(number) * Math.pow(10, digits)) /
        Math.pow(10, digits);
      return number < 0
        ? (roundedNumber * -1).toFixed(digits)
        : roundedNumber.toFixed(digits);
    }
  }

  function Export2Pdf() {
    var filteredItems = dataView.getFilteredItems();
    var uitgevoerdeItems = filteredItems.filter(function (item) {
      var status =
        $rootScope.refdata.onderzoekstype[item.onderzoekstype].onderzoekstatus[
          item.onderzoekstatus
        ].volgnummer;
      return status >= 300 && status < 900 && item.xystatus === 'XYZ_Veldwerk';
    });
    var points = [];
    var hasTagklant = false;
    for (var i in uitgevoerdeItems) {
      if (
        uitgevoerdeItems[i].tagklant != null &&
        uitgevoerdeItems[i].tagklant != ''
      ) {
        hasTagklant = true;
      }
    }
    for (var ii in uitgevoerdeItems) {
      var roundedX = round(uitgevoerdeItems[ii].x, 1);
      var roundedY = round(uitgevoerdeItems[ii].y, 1);
      var roundedZ = round(uitgevoerdeItems[ii].z, 2);
      var pointedX = roundedX;
      var pointedY = roundedY;
      var signedZ =
        roundedZ == 'NULL' ? ' ' : roundedZ == 0 ? '0.00' : roundedZ;
      var label = uitgevoerdeItems[ii].naam;
      if (hasTagklant) {
        var tagklant =
          uitgevoerdeItems[ii].tagklant != null
            ? uitgevoerdeItems[ii].tagklant
            : '';
        points.push(
          label +
            ';' +
            tagklant +
            ';' +
            pointedX +
            ';' +
            pointedY +
            ';' +
            signedZ,
        );
      } else {
        points.push(label + ';' + pointedX + ';' + pointedY + ';' + signedZ);
      }
    }
    var joinedPoints = points.join(',');
    var datum = utilService.dateStringForFilenames(new Date());
    const project = modelService.getModel().project;
    var subprojectNr = project ? 'VN-' + project.subprojectnr : '';
    var fileName = (subprojectNr || 'KAART') + '_XYZ_' + datum;
    var data = {
      layout: hasTagklant ? 'xyzTag' : 'xyzNoTag',
      outputFormat: 'pdf',
      outputFilename: fileName,
      attributes: {},
    };
    const projection = mapexportService.projection(uitgevoerdeItems);
    data.attributes.xysysteem = projection.xy;
    data.attributes.zsysteem = projection.z;
    data.attributes.listOfItems = joinedPoints;
    data.attributes.datum = datum;
    data.attributes.projectnr = '' + subprojectNr;
    userAccountService.createDoc(data);
  }

  function Export2Kml(kmz) {
    const items = dataView.getFilteredItems();
    const colItems = [];
    for (const item of items) {
      const thisRow = {};
      const [x, y] = item.coordinate;
      thisRow.x = x;
      thisRow.y = y;
      thisRow.z = item.z || 0;
      thisRow.naam = item.naam || '';
      thisRow.type = item.onderzoekstype;
      var status =
        $rootScope.refdata.onderzoekstype[item.onderzoekstype].onderzoekstatus[
          item.onderzoekstatus
        ].volgnummer;
      thisRow.kleur =
        status >= 300 && status < 900 && item.xystatus === 'XYZ_Veldwerk'
          ? 'blauw'
          : 'grijs';
      colItems.push(thisRow);
    }
    const project = modelService.getModel().project;
    const filename = project ? project.subprojectnr : 'coordinaten';
    userAccountService
      .dataViewAsKml(colItems, kmz)
      .then((response) => response.blob())
      .then((blob) => {
        const extension = kmz ? 'kmz' : 'kml';
        downloadLocalData(`${filename}.${extension}`, blob);
      });
  }

  let selectedView;
  function setView(element, columns, views, viewIndex) {
    selectedView = views[viewIndex];
    var viewColumns = [];
    if (selectedView != null) {
      for (var i in selectedView.columns) {
        viewColumns.push(
          columns.filter(getColumnById, { id: selectedView.columns[i] })[0],
        );
      }
    } else {
      viewColumns.push(columns);
    }
    var headerrowVisible = grid.getOptions().showHeaderRow;
    grid.setHeaderRowVisibility(true);
    setColumns(viewColumns);
    setFilter();
    grid.invalidate();
    grid.render();
    grid.setHeaderRowVisibility(headerrowVisible);
    var sc = angular.element(element).scope();
    sc.selectedView = sc.views[viewIndex];

    dataView.refresh();
    setGridRowIds();
  }

  function getColumnById(column) {
    return column.id == this.id;
  }

  function getFromAllColumns(columnId) {
    return allColumns.filter(function (c) {
      return c.id == columnId;
    })[0];
  }

  return factory;
}
